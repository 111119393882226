import React from 'react'
import {blockquote} from './Quote.module.css'

const Quote = (props) => {
  return (
  <div data-aos="fade-up" style={{maxWidth: 1000, margin: 'auto', marginTop: 50}}>
    <blockquote className={blockquote}>
   {props.text}
    <span>{props.author}</span>
  </blockquote>
  </div>
  )
}

export default Quote
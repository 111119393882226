import React from 'react'
import {Link} from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";
import { renderSingleImage } from '../../utils/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {p, titleSection, left} from './LeftRight.module.css'


const LeftRight = (props) => {



  return (
		<Container fluid style={{background: props.background || 'rgb(48 194 220 / 4%)'}}>
			<Container fluid style={{maxWidth: 1400}}>
					
			<Row style={{marginBottom: -15, marginTop: 0}} className="flex-column-reverse flex-sm-row">
				<Col>
		
				<div id={left}>
				<div className={titleSection}>
							<h2>{props.title}</h2>
							<span>{props.subTitle}</span>
					</div>

				<p className={p}>{props.text1}</p>
				<p className={p}>{props.text2}
				</p>
				<p><Link to={props.defaultLead} style={{color:props.defaultButtonColor}}>{props.cta || props.defaultCta} <FontAwesomeIcon icon={faArrowRight} /></Link></p>

				</div>
				</Col>
				<Col style={{marginRight: -15, minWidth: 400}}>	<div className='heroPic'  data-aos="fade-left" data-aos-duration="750" >
					{renderSingleImage(props.localImages, props.image, 'heroImage')}
					</div></Col>
			</Row>
		</Container>
	</Container>
  )
}

export default LeftRight
import React from 'react'
import {Container, Row, Col, Button} from "react-bootstrap"
import styled from "styled-components"
import {Link} from 'gatsby'
import { device } from "../../utils";
import {Section} from "../../components/Core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { renderSingleImage } from '../../utils/images';
import van from "../../assets/image/png/van-blank.png";
import house from "../../assets/image/png/house2.png";
import flowerbed from "../../assets/image/png/flowerbed.png";

const SectionStyled = styled(Section)`
  background-image: linear-gradient(to right, #1f212b , #0023ff);
  background-size: cover;
  background-position: bottom left;
  padding-top: 2px;
  
  @media ${device.lg} {
    background-position: center;
  }
`;

function Hero(props) {

  const logo = () => {
    if(props.logos.includes(`${props.subDomain}%2Flogo-black`)) return `${props.subDomain}%2Flogo-black`
    else return `${props.subDomain}%2Flogo`
  }
 

  return (

    <SectionStyled style={{backgroundImage: props.bg || props.defaultBg}}>
        <Container fluid id='top' style={{marginTop: -100}}>
          <Container style={{maxWidth: 1280}}>
        <Row id='topColRow' style={{marginBottom: -52, marginLeft: -20, marginRight: -30}}>
          <Col className='colcol' id='heroLeftCol'>
            <div >
            <h1 id='topColH1Desk'>{props.title}</h1>
            <h1 id='topColH1Mob'>{props.titleMobile}</h1>
            <h3 id='topColH3'>{props.subTitle}</h3>
     
            <Link to={props.link || props.leadUrl} 
            style={{marginTop: 0, marginBottom: 30, marginLeft: 10}}>
              <Button className="btn-lg btn btn btn-primary"
              data-aos="fade-right" data-aos-duration="500"
              id='topColButton'
              style={{fontWeight: 700, fontFamily: 'Montserrat', paddingTop: 10, height: 60, minWidth: 250, background: props.defaultButtonColor , borderColor: props.defaultButtonColorBorder, color: props.defaultButtonFontColor}}
              >
              {props.cta || props.defaultCta} <FontAwesomeIcon icon={faArrowRight} />
              </Button>
              </Link>
              </div>
          </Col>
          <Col sm={6} id='heroRightCol'>
            {props.image && 
        <>   
          <Row>
          <Col className='colcol' style={{marginLeft: -30}}>
          <Link to={props.link || props.leadUrl}>
            <div  data-aos="fade-right" data-aos-duration="750" data-aos-once="true" data-aos-delay="1000" id='extra'
            style={{
            background: 'rgb(26 33 85 / 64%)',
            borderRadius: 5,
            padding: 20,
            marginBottom: -30,
            marginLeft: 60,
            marginTop: -60,
            width: 338}}>

              <div style={{width: 20, height: 20, borderRadius: 50, background: '#fba03a', float: 'left'}}>
                <i className="icon icon-check-2" style={{color: 'black', fontWeight: 700, padding: 1}}></i>
              </div>

              <div style={{marginLeft: 30}}>
              <span style={{color: 'white'}}>{props.feature1}</span>
              </div>
            </div>
          </Link>
          <Link to={props.link || props.leadUrl}>
          <div  data-aos="fade-left" data-aos-duration="750" data-aos-once="true" data-aos-delay="1500" id='extra' 
          style={{
           background: 'rgb(26 33 85 / 64%)',
           borderRadius: 5,
           padding: 20,
           marginBottom: -30,
           marginLeft: 280,
           marginTop: 120,
           width: 318}}>

            <div style={{width: 20, height: 20, borderRadius: 50, background: '#fba03a', float: 'left'}}>
              <i className="icon icon-check-2" style={{color: 'black', fontWeight: 800, padding: 3, fontSize: 14}}></i>
            </div>

            <div style={{marginLeft: 30}}>
            <span style={{color: 'white'}}>{props.feature2}</span>
            </div>
          </div>
          </Link>
          <Link to={props.link || props.leadUrl}>
          <img src={house} alt="" id='house' style={{height: 380, marginTop: -150, marginLeft: -30}}/>
          </Link>
            <Link to={props.link || props.leadUrl}>
              <div data-aos="fade-left" data-aos-duration="1000" data-aos-delay="250" style={{marginTop: -166, marginLeft: 88}}>
              <img src={van} alt="" id='van'/>
              <div style={{marginTop: -239, marginLeft:327}}>
                {renderSingleImage(props.localImages, logo(), 'vanLogo', props.subDomain)}
              </div>
              </div>
            </Link>
            <Link to={props.link || props.leadUrl}>
              <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="2000" style={{marginTop: -26, marginLeft: -93}}>
              <img src={flowerbed} alt="" id='plant' style={{width: 200}}/>
              </div>
            </Link>
          </Col>
          </Row>
          </>

          }

          </Col>
          </Row>
          </Container>
        </Container>
        </SectionStyled>

  )

}

export default Hero
import React from 'react';
import {Container, Row, Col} from "react-bootstrap"
import { Text } from "../../components/Core";
import {Link} from 'gatsby'
import { renderSingleImage } from '../../utils/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'

const What = (props) => {

  const smallImageId = 'heroSmallImage'
  return (

  <Container style={{maxWidth: 1280, marginBottom: 130}}>
<div style={{textAlign: 'center', marginTop: 120, marginBottom: 50}}>
      <h2 style={{ marginTop: 120, marginBottom :20}}>{props.title}</h2>
      <Text style={{marginBottom : 65}}>{props.subTitle}</Text>
    </div>
    <Row style={{maxWidth: 1200, margin: 'auto'}}>
      <Col sm={6} style={{paddingLeft: 5, paddingRight: 5}} data-aos-duration="750">
  
        <div id='quoiP'><Text>{props.text1}</Text></div>
        <div id='quoiP'><Text>{props.text2}</Text></div>


       <Link to={props.leadUrl} style={{fontSize: 20}}>{props.defaultCta} <FontAwesomeIcon icon={faArrowRight} /></Link>
        </Col>
      
         <Col >
          { props.localImages && 
          <Link to={props.link || props.leadUrl}>
            <div data-aos="zoom-in" data-aos-duration="500" id='whatImageDiv'>
            {renderSingleImage(props.localImages, props.image1, smallImageId)}
            </div>
          </Link>
          }

          {
          <Link to={props.link || props.leadUrl}>
            <div data-aos="zoom-in" data-aos-duration="500" data-aos-delay="750" id='whatImageDiv'>
            {renderSingleImage(props.localImages, props.image2, smallImageId)}
            </div>
          </Link>
          }   
        </Col>

        <Col >
        { props.localImages && 
        <Link to={props.link || props.leadUrl}>
          <div data-aos="zoom-in" data-aos-duration="500" data-aos-delay="500" id='whatImageDiv'>
            {renderSingleImage(props.localImages, props.image3, smallImageId)}
          </div>
        </Link>
        }
        { props.localImages && 
        <Link to={props.link || props.leadUrl}>
          <div data-aos="zoom-in" data-aos-duration="500" data-aos-delay="1000" id='whatImageDiv'>
            {renderSingleImage(props.localImages, props.image4, smallImageId)}
          </div>
        </Link>
        }
        </Col>

      
    </Row>
  </Container>
     
  )

}

export default What
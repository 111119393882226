import React from 'react'
import {Accordion, Container} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Title, Text } from "../../components/Core";

function AccordionC(props) {

    return (
      // {props.content === true ? 



      <Container style={{maxWidth: 1000}}>
        <div style={{textAlign: 'center', marginTop: 120, marginBottom: 50}}>
        <h2>{props.title}</h2>
        <Text style={{marginBottom : 45}}>{props.subTitle}</Text>
        </div>
    
        <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{marginBottom: 0, marginTop: 0}}><Title variant="card" mb={0}>{props.q1}</Title></Accordion.Header>
          <Accordion.Body>
          <Text>{props.a1}
          </Text>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><Title variant="card" mb={0}>{props.q2}</Title></Accordion.Header>
          <Accordion.Body>
          <Text>{props.a2}
          </Text>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><Title variant="card" mb={0}>{props.q3}</Title></Accordion.Header>
          <Accordion.Body>
          <Text>{props.a3}
          </Text>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><Title variant="card" mb={0}>{props.q4}</Title></Accordion.Header>
          <Accordion.Body>
          <Text>{props.a4}
          </Text>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header><Title variant="card" mb={0}>{props.q5}</Title></Accordion.Header>
          <Accordion.Body>
          <Text>{props.a5}
          </Text>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </Container>
   
    )
}

export default AccordionC
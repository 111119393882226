import React from 'react'
import {Container, Button} from "react-bootstrap"
import {Link} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { renderSingleImage } from '../../utils/images';


function Hero(props) {


  return (


        <Container fluid id="heroPicture" style={{background: props.opacityBackground, marginTop: props.marginTop, paddingLeft: 0, paddingRight: 0, position: 'relative'}}>
     
          <div className='heroPic' style={{height: props.height, maxHeight: props.maxHeight, display: 'flex', justifyContent: 'center',  alignItems: 'center', position: 'relative', background: 'black', opacity: props.opacity}}>
          
            {renderSingleImage(props.localImages, props.bgImage, 'heroImage')}
       
          </div>


          <Container className="text-center" style={{zIndex: 10, position: 'absolute', left: '50%', transform: 'translateX(-50%)', top: 140, maxWidth: 1280}}>


                <div id='shade' style={{background: props.shade}}>
                <h1 id={props.heroH1 || 'topColH1Desk'} style={{fontFamily: props.topColH1DeskFontFamily, marginTop: props.topColH1DeskMarginTop}}>{props.title}</h1>
                <h1 id={props.heroH1Mob || 'topColH1Mob'} style={{fontFamily: props.topColH1DeskFontFamily, marginTop: 82}}>{props.titleMobile}</h1>
                <h3 id={props.heroSubtitle || 'topColH3'} style={{marginBottom : 0}}>{props.subTitle}</h3>
        
                <Link to={props.link || props.leadUrl} 
                style={{marginTop: -40, marginBottom: 30, marginLeft: 10}}>
                  <Button className="btn-lg btn btn btn-primary"
                  data-aos="fade-right" data-aos-duration="500"
                  id='topColButton'
                  style={{fontWeight: 700, fontFamily: 'Montserrat', paddingTop: 10, height: 60, minWidth: 250, background: props.defaultButtonColor , borderColor: props.defaultButtonColorBorder, color: props.defaultButtonFontColor}}
                  >
                  {props.cta || props.defaultCta} <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                  </Link>
                  </div>

            </Container>
            
        </Container>
  

  )

}

export default Hero

import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import Index from '../components/Pages/Index';

function IndexTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Index } />
    )

}

export const queryIndex = graphql`
fragment indexFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            images
            seoTitle
            seoDescription
            oneTwoThree {
                image1
                image2
                image3
                text1
                text2
                text3
                title1
                title2
                title3
            }
            hero {
                cta
                image
                image1
                image2
                image3
                image4
                link
                subTitle
                title
                titleMobile
                type
                bgImage
                bg
                feature1
                feature2
                style {
                    height
                    heroH1
                    heroH1Mob
                    heroSubtitle
                    maxHeight
                    minHeight
                    marginTop
                    opacity
                    opacityBackground
                    shadeBackground
                    topColH1Desk {
                        fontFamily
                        marginTop
                    }
                }
            }
            stars{
                active
                text1
                text2
                text3
            }
            ticks{
                active
                text1
                text2
                text3
                text4
                title1
                title2
                title3
                title4
            }
            what{
                active
                image
                image1
                image2
                image3
                image4
                text1
                text2
                title
                link
            }
            features{
                active
                title
                subTitle
                icon1
                text1
                title1
                url1
                icon2
                text2
                title2
                url2
                icon3
                text3
                title3
                url3
                icon4
                text4
                title4
                url4
                icon5
                text5
                title5
                url5
                icon6
                text6
                title6
                url6
            }
            accordion{
                active
                a1
                a2
                a3
                a4
                a5
                q1
                q2
                q3
                q4
                q5
            }
            carousel{
                active
                images
                title
                subTitle
            }
            salesPitch{
                list
                quote
                quoteImage
            }
            gallery{
                active
                images
                subTitle
                title
            }
            leftRight{
                active
                cta
                ctaLink
                image
                subTitle
                text1
                text2
                title
                style {
                    background
                }
            }
            quote{
                active
                text
                author
            }
        }
    }
}
query indexQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...indexFields
    }
}`

export default IndexTemplate

import React from 'react'
import Hero from '../Blocks/Hero'
import HeroPicture from '../Blocks/HeroPicture'
import Features from '../Blocks/Features'
import What from '../Blocks/What'
import Accordion from '../Blocks/Accordion'
import Stars from '../Blocks/Stars'
import Ticks from '../Blocks/Ticks'
import Carousel from "../Blocks/Carousel"
import Gallery from "../Blocks/Gallery"
import LeftRight from "../Blocks/LeftRight"
import Quote from "../Blocks/Quote"
import Seo from '../seo';

function Index(props) {
    
    const {content, localImages} = props;
    const {hero, carousel, stars, ticks, what, features, accordion, leftRight, gallery, quote} = content;


    return (
        <>
        <Seo
            title={props.content.seoTitle || props.content.h1}
            description={props.content.seoDescription}
            metas={props.global.defaults.metas}
        />

   
                {
                    hero && hero.type === 'standard' ? (
                    <Hero 
                        title={hero.title}
                        titleMobile={hero.titleMobile}
                        subTitle={hero.subTitle}
                        image={hero.image}
                        image1={hero.image1}
                        image2={hero.image2}
                        image3={hero.image3}
                        image4={hero.image4}
                        link={hero.link}
                        leadUrl={props.global.defaults.data.lead}
                        defaultCta={props.global.defaults.data.cta}
                        cta={hero.cta}
                        localImages={props.localImages}
                        subDomain={props.global.contact.subDomain}
                        bg={hero.bg}
                        defaultBg={props.global.design.bg}
                        logos={props.global.logos}
                        feature1={hero.feature1}
                        feature2={hero.feature2}
                        defaultButtonColor={props.global.design.buttonColor}
                        defaultButtonColorBorder={props.global.design.buttonBorder}
                        defaultButtonFontColor={props.global.design.buttonFontColor}
                    />
                    ) :
                    <HeroPicture
                    title={hero.title}
                    titleMobile={hero.titleMobile}
                    subTitle={hero.subTitle}
                    image={hero.image}
                    link={hero.link}
                    leadUrl={props.global.defaults.data.lead}
                    defaultCta={props.global.defaults.data.cta}
                    cta={hero.cta}
                    bgImage={hero.bgImage}
                    localImages={props.localImages}
                    defaultButtonColor={props.global.design.buttonColor}
                    defaultButtonColorBorder={props.global.design.buttonBorder}
                    defaultButtonFontColor={props.global.design.buttonFontColor}
                    height={hero.style?.height}
                    maxHeight={hero.style?.maxHeight}
                    minHeight={hero.style?.minHeight}
                    marginTop={hero.style?.marginTop}
                    shade={hero.style?.shadeBackground}
                    opacityBackground={hero.style?.opacityBackground}
                    opacity={hero.style?.opacity}
                    topColH1DeskFontFamily={hero.style?.topColH1Desk.fontFamily}
                    topColH1DeskMarginTop={hero.style?.topColH1Desk.marginTop}
                    heroH1={hero.style?.heroH1}
                    heroH1Mob={hero.style?.heroH1Mob}
                    heroSubtitle={hero.style?.heroSubtitle}
                />

                
                }
                {
                    ticks && ticks.active ===  true ? 
                    <Ticks
                    title1={ticks.title1}
                    text1={ticks.text1}
                    title2={ticks.title2}
                    text2={ticks.text2}
                    title3={ticks.title3}
                    text3={ticks.text3}
                    title4={ticks.title4}
                    text4={ticks.text4}
                    /> : null
                }
        
  

            <section style={{background: 'white'}}>
                
                {stars && stars.active ===  true ? 
                <Stars
                    text1={stars.text1}
                    text2={stars.text2}
                    text3={stars.text3}/>
                : null}

                {what && what.active ===  true ?  
                <What
                    title={what.title}
                    subTitle={what.subTitle}
                    text1={what.text1}
                    text2={what.text2}
                    image={what.image}
                    image1={what.image1}
                    image2={what.image2}
                    image3={what.image3}
                    image4={what.image4}
                    link={what.link}
                    localImages={props.localImages}
                    defaultCta={props.global.defaults.data.cta}
                    defaultLead={props.global.defaults.data.lead}
                />
                : null}

                {what && what.active ===  true ?
                <Features
                    title={features.title}
                    subTitle={features.subTitle}
                    title1={features.title1}
                    text1={features.text1}
                    url1={features.url1}
                    icon1={features.icon1}
                    title2={features.title2}
                    text2={features.text2}
                    url2={features.url2}
                    icon2={features.icon2}
                    title3={features.title3}
                    text3={features.text3}
                    url3={features.url3}
                    icon3={features.icon3}
                    title4={features.title4}
                    text4={features.text4}
                    url4={features.url4}
                    icon4={features.icon4}
                    title5={features.title5}
                    text5={features.text5}
                    url5={features.url5}
                    icon5={features.icon5}
                    title6={features.title6}
                    text6={features.text6}
                    url6={features.url6}
                    icon6={features.icon6}
                    leadUrl={props.global.defaults.data.lead}
                /> : null}
                {carousel && carousel.active ===  true ?
            
                
                <Carousel
                    title={carousel.title}
                    subTitle={carousel.subTitle}
                    localImages={props.localImages}
                    imageNames={carousel.images}
                    /> 
             
          
                   : null}

                {quote && quote.active ===  true ?
            
                    <Quote
                        text={quote.text}
                        author={quote.author}
                    />
            
                : null }

                {leftRight && leftRight.active === true ?
                
                
                        <LeftRight
                            title={leftRight.title}
                            subTitle={leftRight.subTitle}
                            cta={leftRight.cta}
                            ctaLink={leftRight.ctaLink}
                            text1={leftRight.text1}
                            text2={leftRight.text2}
                            localImages={props.localImages}
                            image={leftRight.image}
                            defaultCta={props.global.defaults.data.cta}
                            defaultLead={props.global.defaults.data.lead}
                            defaultButtonColor={props.global.design.buttonColor}
                            background={leftRight.style?.background}
                        />
         
                : null}
       
                {gallery && gallery.active ===  true ?
                                
                                        <Gallery
                                            title={gallery.title}
                                            subTitle={gallery.subTitle}
                                            localImages={props.localImages}
                                            imageNames={gallery.images.slice(0,8)}
                                            /> 
                            
                                : null}

                {accordion && accordion.active ===  true ?
                <Accordion
                    title={accordion.title}
                    subTitle={accordion.subTitle}
                    q1={accordion.q1}
                    a1={accordion.a1}
                    q2={accordion.q2}
                    a2={accordion.a2}
                    q3={accordion.q3}
                    a3={accordion.a3}
                    q4={accordion.q4}
                    a4={accordion.a4}
                    q5={accordion.q5}
                    a5={accordion.a5}
                    active={accordion.active}
                />
                : null}

            </section>
     
        </>
    )
}

export default Index
import React from 'react'
import * as Bs from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {Text } from "../../components/Core";

function Tick(props) {
    return (
    
              <Bs.Container fluid style={{paddingTop: 50,paddingBottom: 50, paddingLeft: 27, paddingRight: 27, background: 'rgb(251 160 58)'}} id='tickPosition'>
            <Bs.Row style={{maxWidth: 1280, margin: 'auto'}}>
              <Bs.Col sm>
              <h3 style={{color: '#212529', fontSize: 28, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left'}}> <FontAwesomeIcon icon={faCheck} size="1x"/> {props.title1}</h3>
                <Text style={{textAlign: 'left', lineHeight: '38px', marginTop: 20}}>{props.text1}</Text>
              </Bs.Col>
              <Bs.Col sm id='tickBox'>
              <h3 style={{color: '#212529', fontSize: 28, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left'}} id='tickh3'> <FontAwesomeIcon icon={faCheck} size="1x" style={{fontWeight: 700}} /> {props.title2}</h3>
              <Text style={{textAlign: 'left', lineHeight: '38px', marginTop: 20}}>{props.text2}</Text>
              </Bs.Col>
              <Bs.Col sm id='tickBox'>
              <h3 style={{color: '#212529', fontSize: 28, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left'}} id='tickh3'> <FontAwesomeIcon icon={faCheck} size="1x"/> {props.title3}</h3>
              <Text style={{textAlign: 'left', lineHeight: '38px', marginTop: 20}}>{props.text3}</Text>
              </Bs.Col>
              <Bs.Col sm id='tickBox'>
              <h3 style={{color: '#212529', fontSize: 28, fontWeight: 700, letterSpacing: -0.66, lineHeight: '28px', textAlign: 'left'}} id='tickh3'> <FontAwesomeIcon icon={faCheck} size="1x"/> {props.title4}</h3>
              <Text style={{textAlign: 'left', lineHeight: '38px', marginTop: 20}}>{props.text4}</Text>
              </Bs.Col>div
            </Bs.Row>
          </Bs.Container>
 
    )
}

export default Tick